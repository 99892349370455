<template>
    <body class="login">
        <main id="login" class="wrapper wrapperBd">

            <div class="liPankuzu">
                <ul>
                    <li><router-link to="/">ホーム</router-link></li>
                    <li>ログイン</li>
                </ul>
            </div>
            <!-- li-pankuzu -->

            <section class="secMainview01">
                <div class="secMainview01Inner container containerLarge">
                    <h1 class="secMainview01Title">
                        <img src="/assets/img/common/txtLogin.svg" alt="ログイン" class="secMainview01Jp" />
                    </h1>
                    <div class="effectBlock effectBlock04"></div>
                </div>
            </section>
            <!-- secMainview01 -->

            <div class="container">

                <div class="loginIntro">
                    <p>OTEX2024オンライン展をご覧いただきありがとうございます。<br>
                    これ以降のページの閲覧にはログインが必要となります。</p>
                    <p><small>来場登録済みの方は、IDとパスワードをご入力ください。<br/>
                    初めてのご来場の方は、新規登録ボタンよりご登録をお願いいたします。</small></p>
                    <div class="effectBlock effectBlock05"></div>
                </div>

                <div class="loginForm">
                    <div class="error" v-if="errors_500">
                        <p>{{errors_500}}</p>
                    </div>
                    <form name="login_form" @submit.prevent="onSubmit">
                        <table>
                            <tbody>
                                <tr>
                                    <th>アカウントID</th>
                                    <td><input type="email" v-model="email" placeholder="ユーザー名 または メールドレス"></td>
                                </tr>
                                <tr>
                                    <th>パスワード</th>
                                    <td>
                                        <input type="password" v-model="password" placeholder="パスワードを入力">
                                        <p><a href="https://miceform.jp/otex2023/form/ps_reset" target="_blank" rel="noopener">パスワードを忘れた場合はこちら</a></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <input type="button" value="ログイン" @click="onSubmit">
                    </form>
                </div>

                <div class="loginOutro">
                    <a class="btn" href="https://miceform.jp/otex2023/form/" target="_blank" rel="noopener">
                        <img src="/assets/img/common/iconAdminWhite.svg" alt="" width="22.48" height="22.86" /><span>新規会員登録はこちら</span>
                    </a>
                </div>
            </div>
        </main>

        <!--
        <main id="login" class="login">
            <form name="login_form" @submit.prevent="onSubmit">
                <ul v-if="errors_500" style="margin-bottom: 30px; color: red;">
                    <li>{{errors_500}}</li>
                </ul>
                <div class="login_form_top">
                    <h1>ログイン画面</h1>
                    <p>メールアドレス、パスワードをご入力の上、「ログイン」ボタンをクリックしてください。</p>
                </div>
                <div class="login_form_btm">
                    <input type="email" v-model="email" placeholder="メールアドレスを入力してください" />
                    <p>{{ emailError }}</p>
                    <br>
                    <input type="password" v-model="password" placeholder="パスワードを入力してください" />
                    <p>{{ passwordError }}</p>
                </div>
                <button type="submit">ログイン</button>
            </form>
        </main>
        -->
    </body>
</template>

<script setup>
    import { ref, inject, reactive, computed, watch, onMounted } from 'vue';
    import { useField, useForm, configure } from 'vee-validate';
    import { object, string } from 'yup';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { useToast } from "vue-toastification";
    const toast = useToast();

    const store = useStore();
    const $axios = inject('$axios');
    const router = useRouter();

    // インポートした「configure」を使用してトリガするイベントを変更
    configure({
        validateOnBlur: true, // blurイベントで検証をトリガーする必要がある場合、デフォルトはtrue
        validateOnChange: true, // changeイベントで検証をトリガーする必要がある場合、デフォルトはtrue
        validateOnInput: true, // inputイベントで検証をトリガーする必要がある場合、デフォルトはfalse
        validateOnModelUpdate: true, // update:modelValue（v-model）イベントで検証をトリガーする必要がある場合、デフォルトはtrue
    });

    const formData = reactive({
        email: '',
        password: '',
    });

    const errors_500 = ref('');

    //ログイン処理
    const handleLogin = async () => {
        email.value ? formData.email = email.value : '';
        password.value ? formData.password = password.value : '';

        //console.log(formData);

        await $axios.get('/sanctum/csrf-cookie')
            .then((res) => {
                $axios.post('/api/login', formData)
                    .then((res) => {
                        console.log(res);
                        //storeの値変更
                        store.commit('setAuthFlag', true);
                        store.commit('setCurrentUser', res.data);
                        sessionStorage.setItem('unAuthReloadFlag', true);
                        //store.dispatch('checkAuth');
                        const currentUser = store.state.currentUser;
                        console.log(currentUser.role);
                        //ログイン記録を送る res.data.roleはユーザー
                        window.gtag('event', "custom_user_event", {
                            'event_category': "access_login",
                            'event_label': "LOGIN",
                            'user_role': res.data.role,
                            'value' : 1
                        });
                        //学生なら学生カウント用ログを送る
                        //if (res.data.role == 2) {
                        //    window.gtag('event', "custom_user_event", {
                        //        'event_category': "page_view_student",
                        //        'event_label': res.data.name,
                        //        'value' : 1
                        //    });
                        //    //学生ログインユーザーでログを取れたら
                        //    //その後トップページに行った時の際に二重ログとりになるのでそれを防止
                        //    store.commit('setTopPageLogSubmittedFlag');
                        //}
                        //console.log(res.data.role + 'ログイン記録をgtagへ保存');

                        setTimeout(() => {
                            const failedPath = sessionStorage.getItem('failedPath');
                            if (failedPath) {
                                router.push(failedPath);
                            } else {
                                router.push('/');
                            }
                        }, 200);

                    })
                    .catch((err) => {
                        console.log(err.response);
                        errors_500.value = 'メールアドレスまたはパスワードが間違っています。';
                        //if (err.response.status == 422) {
                        //    errors_422.value = err.response.data.errors;
                        //} else {
                        //    errors_500.value = err.response.data.msg;
                        //}
                    });
            })
        .catch((err) => {});
    };

    //バリデーション処理
    //const { value: email, errorMessage: emailError } = useField(
    //    'email',
    //    yup.string().required("メールアドレスは必須項目です"),
    //);
    //const { value: password, errorMessage: passwordError } = useField(
    //    'password',
    //    yup.string().required("パスワードは必須項目です"),
    //);

    const schema = object({
        password: string().required("パスワードは必須項目です"),
        email: string().required("メールアドレスは必須項目です"),
    });

    const { errors, handleSubmit } = useForm({
    validationSchema: schema,
        initialValues: {
            password: '',
            email: '',
        },
    });

    const onSubmit = handleSubmit((values) => {
        console.log(values);
        //ログイン処理
        handleLogin();
    });

    const { value: password,errorMessage: passwordError } = useField('password');
    const { value: email,errorMessage: emailError } = useField('email');

    //mounted
    onMounted(() => {
        //もし認証してない状態でトップページから講演またはプレゼン画面に行って
        //ログインページへリダイレクトされたときにトップから来ているフラグをリセットさせる
        //こうしないとリダイレクトされた後に講演やプレゼンページに遷移する時に再びリダイレクトされてしまう為
        if (store.state.unAuthLinkFromTopPageFlag == true) {
            store.commit('resetUnAuthLinkFromTopPageFlag');
        }
    })
</script>