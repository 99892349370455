import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import createStoreInstance from "./store";
import "../public/assets/scss/style.scss";
import axiosPlugin from './plugins/axiosPlugin'; 
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

//Vuexストアの初期化を行うPromiseを作成する
const storePromise = new Promise((resolve) => {
    resolve(createStoreInstance(axiosPlugin));
});

// アプリケーションを作成
const app = createApp(App);

// Vuexストアの初期化が完了したらアプリケーションをマウントする
storePromise.then(store => {
  store.dispatch('checkAuth').then(()=>{
    app.use(store).use(router).use(Toast).provide('$axios', axiosPlugin).mount("#app");
  });
});

export default storePromise;