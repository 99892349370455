<template>

    <body class="login">

    <!--<vue-element-loading :active="dataLoading" color="black" spinner="spinner" />-->

    <main id="company" class="wrapper wrapperBd">

        <div class="liPankuzu">
        <ul>
            <li><router-link to="/">ホーム</router-link></li>
            <li>出展企業情報</li>
        </ul>
        </div>
        <!-- li-pankuzu -->

        <section class="secMainview01">
            <h1 class="secMainview01Title">
                <img src="/assets/img/common/txtShuttenKigyo.svg" alt="出展企業情報" class="secMainview01Jp" />
            </h1>
        </section>
        <!-- secMainview01 -->
        <section class="companySearch navSearch active">
        <button class="companySearchBtn">
            <span><img src="/assets/img/common/txtOpen.svg" alt="開く"><img src="/assets/img/common/txtClose.svg" alt="閉じる" /></span>
        </button>

        <div class="navSearchInner container">
            <div class="effectBlock effectBlock05"></div>
            <h2 class="navSearchTitle"><img src="/assets/img/common/searchCompanyTitleBlack.svg" alt="企業を検索する"/></h2>

            <dl class="navSearchBox">
                <dt><img src="/assets/img/common/searchCompanyTitle02Black.svg" alt="キーワードから探す" /></dt>
                <dd><label class="checkbox cat1" for="keyword1"><input id="keyword1" type="checkbox" name="切削・研削" value="切削・研削" v-model="searchParams.industry_check_1"><span>切削・研削</span></label></dd>
                <dd><label class="checkbox cat2" for="keyword2"><input id="keyword2" type="checkbox" name="鋳造・鍛造" value="鋳造・鍛造" v-model="searchParams.industry_check_2"><span>鋳造・鍛造</span></label></dd>
                <dd><label class="checkbox cat3" for="keyword3"><input id="keyword3" type="checkbox" name="溶接・製缶" value="溶接・製缶" v-model="searchParams.industry_check_3"><span>溶接・製缶</span></label></dd>
                <dd><label class="checkbox cat4" for="keyword4"><input id="keyword4" type="checkbox" name="プレス・板金" value="プレス・板金" v-model="searchParams.industry_check_4"><span>プレス・板金</span></label></dd>
                <dd><label class="checkbox cat5" for="keyword5"><input id="keyword5" type="checkbox" name="金型・治具" value="金型・治具" v-model="searchParams.industry_check_5"><span>金型・治具</span></label></dd>
                <dd><label class="checkbox cat6" for="keyword6"><input id="keyword6" type="checkbox" name="表面処理・熱処理・塗装" value="表面処理・熱処理・塗装" v-model="searchParams.industry_check_6"><span>表面処理・熱処理・塗装</span></label></dd>
                <dd><label class="checkbox cat7" for="keyword7"><input id="keyword7" type="checkbox" name="電気・電子機器組立" value="電気・電子機器組立" v-model="searchParams.industry_check_7"><span>電気・電子機器組立</span></label></dd>
                <dd><label class="checkbox cat8" for="keyword8"><input id="keyword8" type="checkbox" name="樹脂成形・ゴム加工" value="樹脂成形・ゴム加工" v-model="searchParams.industry_check_8"><span>樹脂成形・ゴム加工</span></label></dd>
                <dd><label class="checkbox cat9" for="keyword9"><input id="keyword9" type="checkbox" name="設計・ソフト" value="設計・ソフト" v-model="searchParams.industry_check_9"><span>設計・ソフト</span></label></dd>
                <dd><label class="checkbox cat10" for="keyword10"><input id="keyword10" type="checkbox" name="工具" value="工具" v-model="searchParams.industry_check_10"><span>工具</span></label></dd>
                <dd><label class="checkbox cat11" for="keyword11"><input id="keyword11" type="checkbox" name="その他" value="その他" v-model="searchParams.industry_check_11"><span>その他</span></label></dd>
                <dd><label class="checkbox cat12" for="keyword12"><input id="keyword12" type="checkbox" name="FA機器" value="FA機器" v-model="searchParams.industry_check_12"><span>FA機器</span></label></dd>
                <dd><label class="checkbox cat13" for="keyword13"><input id="keyword13" type="checkbox" name="検査・測定機器" value="検査・測定機器" v-model="searchParams.industry_check_13"><span>検査・測定機器</span></label></dd>
                <dd><label class="checkbox cat14" for="keyword14"><input id="keyword14" type="checkbox" name="生産設備関連" value="生産設備関連" v-model="searchParams.industry_check_14"><span>生産設備関連</span></label></dd>
                <dd><label class="checkbox cat15" for="keyword15"><input id="keyword15" type="checkbox" name="AI・IoT" value="AI・IoT" v-model="searchParams.industry_check_15"><span>AI・IoT</span></label></dd>
                <dd><label class="checkbox cat16" for="keyword16"><input id="keyword16" type="checkbox" name="自社製品" value="自社製品" v-model="searchParams.industry_check_16"><span>自社製品</span></label></dd>
                <dd><label class="checkbox cat17" for="keyword17"><input id="keyword17" type="checkbox" name="素材" value="素材" v-model="searchParams.industry_check_17"><span>素材</span></label></dd>
                <dd><label class="checkbox cat18" for="keyword18"><input id="keyword18" type="checkbox" name="ものづくり関連商社・サービス" value="ものづくり関連商社・サービス" v-model="searchParams.industry_check_18"><span>ものづくり関連商社・サービス</span></label></dd>
                <dd><label class="checkbox cat19" for="keyword19"><input id="keyword19" type="checkbox" name="共同出展" value="共同出展" v-model="searchParams.industry_check_19"><span>共同出展事務局</span></label></dd>
                <dd><label class="checkbox cat20" for="keyword20"><input id="keyword20" type="checkbox" name="特別展示" value="特別展示" v-model="searchParams.industry_check_20"><span>特別展示</span></label></dd>
                <dd><label class="checkbox cat21" for="keyword21"><input id="keyword21" type="checkbox" name="工作機械展示" value="工作機械展示" v-model="searchParams.industry_check_21"><span>工作機械展示</span></label></dd>
                <!-- <dd><label class="checkbox cat22" for="keyword22"><input id="keyword22" type="checkbox" name="工作機械展示" value="工作機械展示" v-model="searchParams.industry_check_22"><span>基調講演関連展示</span></label></dd> -->
            </dl>
            <dl class="navSearchBox">
                <dt><img src="/assets/img/common/searchCompanyTitle03Black.svg" alt="企業の強みから探す" /></dt>
                <dd><label class="checkbox" for="strong1"><input id="strong1" type="checkbox" name="提案力" value="提案力" v-model="searchParams.advantage_1"><span>提案力</span></label></dd>
                <dd><label class="checkbox" for="strong2"><input id="strong2" type="checkbox" name="微細加工" value="微細加工" v-model="searchParams.advantage_2"><span>微細加工</span></label></dd>
                <dd><label class="checkbox" for="strong3"><input id="strong3" type="checkbox" name="難削材加工" value="難削材加工" v-model="searchParams.advantage_3"><span>難削材加工</span></label></dd>
                <dd><label class="checkbox" for="strong4"><input id="strong4" type="checkbox" name="コスト削減" value="コスト削減" v-model="searchParams.advantage_4"><span>コスト削減</span></label></dd>
                <dd><label class="checkbox" for="strong5"><input id="strong5" type="checkbox" name="短納期" value="短納期" v-model="searchParams.advantage_5"><span>短納期</span></label></dd>
                <dd><label class="checkbox" for="strong6"><input id="strong6" type="checkbox" name="大物加工" value="大物加工" v-model="searchParams.advantage_6"><span>大物加工</span></label></dd>
                <dd><label class="checkbox" for="strong7"><input id="strong7" type="checkbox" name="一貫生産" value="一貫生産" v-model="searchParams.advantage_7"><span>一貫生産</span></label></dd>
                <dd><label class="checkbox" for="strong8"><input id="strong8" type="checkbox" name="小ロット" value="小ロット" v-model="searchParams.advantage_8"><span>小ロット</span></label></dd>
                <dd><label class="checkbox" for="strong9"><input id="strong9" type="checkbox" name="高精度" value="高精度" v-model="searchParams.advantage_9"><span>高精度</span></label></dd>
                <dd><label class="checkbox" for="strong10"><input id="strong10" type="checkbox" name="効率化" value="効率化" v-model="searchParams.advantage_10"><span>効率化</span></label></dd>
            </dl>

            <dl class="navSearchBox">
                <dt><img src="/assets/img/common/searchCompanyTitle05Black.svg" alt="50音別"></dt>
                <dd><label class="checkbox" for="onbetsu1"><input id="onbetsu1" type="checkbox" name="ア行" value="ア行" v-model="searchParams.ruby_order_1"><span>ア行</span></label></dd>
                <dd><label class="checkbox" for="onbetsu2"><input id="onbetsu2" type="checkbox" name="カ行" value="カ行" v-model="searchParams.ruby_order_2"><span>カ行</span></label></dd>
                <dd><label class="checkbox" for="onbetsu3"><input id="onbetsu3" type="checkbox" name="サ行" value="サ行" v-model="searchParams.ruby_order_3"><span>サ行</span></label></dd>
                <dd><label class="checkbox" for="onbetsu4"><input id="onbetsu4" type="checkbox" name="タ行" value="タ行" v-model="searchParams.ruby_order_4"><span>タ行</span></label></dd>
                <dd><label class="checkbox" for="onbetsu5"><input id="onbetsu5" type="checkbox" name="ナ行" value="ナ行" v-model="searchParams.ruby_order_5"><span>ナ行</span></label></dd>
                <dd><label class="checkbox" for="onbetsu6"><input id="onbetsu6" type="checkbox" name="ハ行" value="ハ行" v-model="searchParams.ruby_order_6"><span>ハ行</span></label></dd>
                <dd><label class="checkbox" for="onbetsu7"><input id="onbetsu7" type="checkbox" name="マ行" value="マ行" v-model="searchParams.ruby_order_7"><span>マ行</span></label></dd>
                <dd><label class="checkbox" for="onbetsu8"><input id="onbetsu8" type="checkbox" name="ヤ行" value="ヤ行" v-model="searchParams.ruby_order_8"><span>ヤ行</span></label></dd>
                <dd><label class="checkbox" for="onbetsu9"><input id="onbetsu9" type="checkbox" name="ラ行" value="ラ行" v-model="searchParams.ruby_order_9"><span>ラ行</span></label></dd>
                <dd><label class="checkbox" for="onbetsu10"><input id="onbetsu10" type="checkbox" name="ワ行" value="ワ行" v-model="searchParams.ruby_order_10"><span>ワ行</span></label></dd>
            </dl>

            <dl class="navSearchBox navSearchBoxHalf1">
                <dt><img src="/assets/img/common/searchCompanyTitle06Black.svg" alt="その他の条件から探す" /></dt>
                <dd><label class="checkbox" for="other1Company"><input id="other1Company" type="checkbox" name="その他の条件" value="企業発見ラリー参加中" v-model="searchParams.is_displayed_in_rally"><span>企業発見ラリー参加中</span></label></dd>
                <dd><label class="checkbox" for="other2Company"><input id="other2Company" type="checkbox" name="その他の条件" value="課題解決掲示板 参加企業" v-model="searchParams.is_displayed_in_boards"><span>課題解決掲示板 参加企業</span></label></dd>
            </dl>

            <dl class="navSearchBox navSearchBoxHalf2">
                <dt><img src="/assets/img/common/searchCompanyTitle04Black.svg" alt="フリーワード検索" /></dt>
                <dd class="navSearchBoxText"><input type="text" name="フリーワードを入力" placeholder="フリーワードを入力" v-model="searchParams.search_word"></dd>
            </dl>

            <label class="navSearchSubmit" for="navSearchSubmit" @click="searchCompanies"><input id="navSearchSubmit" type="submit" value="検索する"></label>

        </div>
        <!--  inner  -->

        </section>
        <!-- companySearch -->

        <section class="companyListview">
        <div class="companyListviewInner container containerLarge">
            <div class="effectBlock effectBlock04"></div>
            <div class="companyListviewIntro container">
            <h2 class="companyListviewTitle"><img src="/assets/img/common/txtKensakuKekka.svg" alt="検索結果" /></h2>
            <p><span>{{companies.length}}</span> 件の企業が見つかりました。</p>
            </div>
            <div class="companyListviewListarea">
            <ul>
                <li v-for="company in company_list" :key="company.id">
                    <router-link :to="`/companyDetail?company_id=${company.id}`" v-bind:class="['linkCompanycard', company.panel_color]" @click="saveCompanyScrollPosition">
                        <div class="linkCompanycardImg">
                            <img v-if="company.background_image_path" :src="company.background_image_path" alt="" @error="altImg">
                            <img v-else src="/assets/img/top/pickupThumb01.jpg" alt="{企業名}" />
                        </div>
                        <span class="linkCompanycardTag">{{company.industry_name}}</span>
                        <span class="linkCompanycardTitle"><em>{{company.company_name}}</em></span>
                        <span class="linkCompanycardExc">{{ truncateText(company.catch_copy) }}</span>
                    </router-link>
                </li>
            </ul>
            </div>
            <div class="companyListviewBtnarea" @click="isMore" v-if="(company_list.length - isMoreCount) >= 0">
                <label class="btnViewmore" for="viewmore"><button id="viewmore" class="btnViewmoreInner" type="button">もっと見る</button></label>
            </div>
        </div>
        </section>
        <!-- companyListview -->

    </main>
    <!-- #pageID -->
    </body>
</template>

<script setup>
    import { onMounted, ref, inject, computed, watch } from 'vue';
    import { onBeforeRouteLeave } from 'vue-router';
    const $axios = inject('$axios');
    import { useStore } from 'vuex';
    const store = useStore();
    import VueElementLoading from "vue-element-loading";

    // refを使って変数を定義
    const btnCompany = ref(null);
    const navSearchCompany = ref(null);
    const companies = ref([]);
    //const count = ref(9);
    const searchParams = computed(() => store.state.searchParams);
    const forceCompanySearch = computed(() => store.state.forceCompanySearch);
    const isMoreCount = computed(() => store.state.isMoreCount);
    const dataLoading = ref(true);

    //console.log(searchParams.value);
    //console.log(forceCompanySearch);

    //もっと見るボタンに使用するcountを監視
    //会社詳細ページに行って戻ってきた時に開けてる状態にしておくため
    watch(isMoreCount, (newValue, oldValue) => {
        //store.commit('plusIsMoreCount', 9);
        console.log(isMoreCount.value);
    });

    //store.state.forceCompanySearchの値がtrue
    //companyページ内で検索が実行されたら検索処理発火
    watch(forceCompanySearch, (newValue, oldValue) => {
        if (store.state.forceCompanySearch == true) {
            searchCompanies();
        }
    });

    // toggleClass関数の実装
    const toggleClass = (element, className) => {
        if (element.classList.contains(className)) {
            element.classList.remove(className);
        } else {
            element.classList.add(className);
        }
    }

    const searchToggleCompany = () => {
        toggleClass(navSearchCompany.value, "active");
    }

    //会社を取得
    const getCompanies = () => {
        $axios.get('/api/get_companies')
            .then((res) => {
                console.log(res);
                companies.value = res.data.companies;
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(()=>{
                dataLoading.value = false;
            });
    }

    //会社を検索
    const searchCompanies = () => {
        const queryString = Object.keys(searchParams.value)
            .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(searchParams.value[key]))
            .join('&');

        $axios.get('/api/search_companies?' + queryString)
            .then((res) => {
                console.log(res);
                companies.value = res.data.companies;
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(()=>{
                console.log(dataLoading.value);
                dataLoading.value = false;
            });
    }

    const isMore = () => {
        //count.value += 9;
        store.commit('plusIsMoreCount', companies.value.length);
    }

    const company_list = computed(() => {
        return companies.value.slice(0, isMoreCount.value);
    });

    const resetSearchParams = (to) => {
        console.log(to.name);
        //企業関連かログインページ以外に行ったら検索条件をreset
        if (to.name != "company" && to.name != "companyDetail" && to.name != "login") {
            store.commit("resetSearchParams");
            store.commit("resetIsMoreCount");
        };
    }

    const altImg = (event) => {
        event.target.src = "/assets/img/top/pickupThumb01.jpg";
    }

    const saveCompanyScrollPosition = () => {
        // スクロールポジションをセッションストレージに保存
        sessionStorage.setItem('companyScrollPosition', window.scrollY);
    }

    const truncateText = (text) => {
        if (text?.length > 100) {
            return text.substring(0, 100) + '...';
        }
        return text;
    };

    // onMountedフックを使ってDOM要素を取得し、クリックイベントを追加
    onMounted(() => {
        btnCompany.value = document.querySelector(".companySearch .companySearchBtn");
        navSearchCompany.value = document.querySelector(".companySearch.navSearch")
        btnCompany.value.addEventListener("click", function () {
            searchToggleCompany();
        })
        searchCompanies();
    });

    onBeforeRouteLeave((to, from, next) => {
        resetSearchParams(to);
        next();
    });

</script>
