<template>
  <header class="header">
    <div class="headerInner">
        <div class="headerLogo">
          <router-link to="/">
            <img src="/assets/img/common/logo.png" alt="OTEX" class="headerLogo01" width="161" height="51" >
            <picture class="headerLogo02">
              <source media="(min-width: 961px)" srcset="/assets/img/common/logoTitle.svg">
              <source media="(max-width: 960px)" srcset="/assets/img/common/logoSp.png">
              <img src="/assets/img/common/logoTitle.svg" alt="おかやまテクノロジー展2024 ONLINE 精鋭企業と出会う技術展示商談会">
            </picture>
          </router-link>
        </div>
        <navi class="headerNav" :style="[currentUser.role != 2 ? '': 'justify-content: right;']">
          <ul class="headerNavList">
            <li>
              <router-link to="/login" class="linkIcon" v-if="authFlag == false"><img src="/assets/img/common/iconLogin.svg" alt="">ログイン</router-link>
              <span class="linkIcon" v-if="authFlag == true" @click="logout"><img src="/assets/img/common/iconLogout.svg" alt="">ログアウト</span>
            </li>
            <li><a href="https://miceform.jp/otex2023/form/" class="linkIcon" target="_blank"><img src="/assets/img/common/iconAdmin.svg" alt="">会員登録</a></li>
            <li v-if="currentUser.role_id != 4"><router-link to="/myPage" class="linkIcon"><img src="/assets/img/common/iconMypage.svg" alt="">マイページ</router-link></li>
          </ul>
          <ul class="headerNavList">
            <li v-if="currentUser.role_id != 4">
              <router-link to="/contact" class="linkIcon02">
                <div class="linkIcon02Inner">
                  <span><img src="/assets/img/common/iconMail.svg" alt="" width="23" height="17" ></span>
                  <b>お問合わせ</b>
                </div>
              </router-link>
            </li>
            <li>
              <router-link to="/enquete" class="linkIcon02">
                <div class="linkIcon02Inner">
                  <span><img src="/assets/img/common/iconEnquete.svg" alt="" width="19" height="23" ></span>
                  <b>アンケート</b>
                </div>
              </router-link>
            </li>
          </ul>
        </navi>
    </div>
    <!-- inner -->

    <nav class="headerSubmenu">
      <ul>
        <li>
          <router-link to="/#about" class="firstload smoothScroll"><span>OTEXとは</span></router-link>
        </li>
        <li>
          <router-link to="/company" class="firstload"><span>出展企業情報</span></router-link>
        </li>
        <li>
          <router-link to="/lecture" class="firstload"><span>基調講演</span></router-link>
        </li>
        <li>
          <router-link to="/presentation" class="firstload"><span>出展者プレゼンテーション</span></router-link>
        </li>
        <li>
          <router-link to="/board" class="firstload"><span>課題解決掲示板</span></router-link>
        </li>
      </ul>
    </nav>
    <!--  submenu  -->

    <nav class="navOpen">
      <div class="navOpenInner">
        <ul class="navOpenInnerList">
          <li><router-link to="/#about" class="smoothScroll">OTEXとは</router-link></li>
          <li><router-link to="/company">出展企業情報</router-link></li>
          <li><router-link to="/lecture">基調講演</router-link></li>
          <li><router-link to="/presentation">出展者プレゼンテーション</router-link></li>
          <li><router-link to="/board">課題解決掲示板</router-link></li>
          <li v-if="currentUser.role_id != 4"><router-link to="/myPage">マイページ</router-link></li>
        </ul>
        <div class="navOpenInnerListBtn">
          <router-link to="/contact" class="btn01 btn01Color" v-if="currentUser.role_id != 4"><img src="/assets/img/common/iconMailWhite.svg" alt="" width="23" height="17" >お問合せ</router-link>
          <router-link to="/enquete" class="btn01"><img src="/assets/img/common/iconEnqueteWhite.svg" alt="" width="19" height="23" >アンケート</router-link>
          <ul>
            <li>
              <router-link to="/login" class="linkIcon" v-if="authFlag == false"><img src="/assets/img/common/iconLogin.svg" alt="">ログイン</router-link>
              <span class="linkIcon" v-if="authFlag == true" @click="logout"><img src="/assets/img/common/iconLogout.svg" alt="">ログアウト</span>
            </li>
            <li><a href="https://miceform.jp/otex2023/form/" class="linkIcon" target="_blank"><img src="/assets/img/common/iconAdmin.svg" alt="">会員登録</a></li>
          </ul>
        </div>
        <div class="navBanner">
          <ul>
            <li>
              <a href="https://www.optic.or.jp/otex/" target="_blank" rel="noopener">
                <picture>
                  <source media="(min-width: 961px)" srcset="/assets/img/common/bannerOtex.png">
                  <source media="(max-width: 960px)" srcset="/assets/img/common/bannerOtexSp.png">
                  <img src="/assets/img/common/bannerOtex.png" alt="おかやまテクノロジー展2024" >
                </picture>
                <span class="linkExternal">OTEX公式HPはこちら<img src="/assets/img/common/iconExternalLink.svg" alt=""></span>
              </a>
            </li>
          </ul>
        </div>
        <!--  navBanner  -->
      </div>
    </nav>
    <!-- nav-open -->
    <div class="hamburger">
      <img src="/assets/img/common/iconBurgerMenuWhite.svg" alt="MENU" class="hamburgerMenu" width="56" height="42" >
      <img src="/assets/img/common/iconBurgerMenuCloseWhite.svg" alt="CLOSE" class="hamburgerClose" width="56" height="42" >
    </div>
    <!-- burger -->
  </header>
  <!-- / .header -->
</template>
<script setup>
  import { ref, onMounted, inject, watch, computed } from 'vue';
  import Splide from '@splidejs/splide';
  import { useToast } from "vue-toastification";
  const toast = useToast();
  import { onBeforeRouteLeave } from 'vue-router';
  import { useStore } from 'vuex';
  //store/index.jsのグローバルデータ
  const store = useStore();
  //main.jsで定義したグローバルaxios
  const $axios = inject('$axios');
  //変数
  const authFlag = computed(() => store.state.authFlag);
  import { useRouter } from 'vue-router';
  const router = useRouter();
  const currentUser = computed(() => store.state.currentUser);

  watch(currentUser, (newValue, oldValue) => {
    console.log('authFlag changed:', '新しい値:'+ newValue, '古い値:'+ oldValue);
  });

  const logout = () => {
    let navOpen = document.querySelector(".navOpen");
    let hamburger = document.querySelector(".hamburger");
    let body = document.querySelector("body");
    navOpen.classList.remove('active');
    hamburger.classList.remove('active');
    body.classList.remove('fixed');

    $axios.post('/api/logout')
      .then((res) => {
        console.log(res);
        //storeの値変更
        store.commit('setAuthFlag', false);
        store.commit('setCurrentUser', '');
        sessionStorage.removeItem('unAuthReloadFlag');
        //toast("ログアウトしました!", {
        //    position: "top-left",
        //    timeout: 1000,
        //    closeOnClick: true,
        //    pauseOnFocusLoss: true,
        //    pauseOnHover: true,
        //    draggable: true,
        //    draggablePercent: 0.6,
        //    showCloseButtonOnHover: false,
        //    hideProgressBar: true,
        //    closeButton: "button",
        //    icon: true,
        //    rtl: false
        //});
        setTimeout(() => {
            router.push('/');
        }, 200);
      })
      .catch((err) => {
          console.log(err);
      });
  }

  let logoutClick = document.querySelectorAll(".navOpen span");
  console.log(logoutClick);

</script>

<style scoped>
  span {
    cursor: pointer;
  }
</style>