<template>
  <Header />
  <transition name="fade">
    <router-view />
  </transition>
  <NavSearch />
  <Footer />
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import NavSearch from "./components/NavSearch.vue";
 
export default {
  name: "App",
  components: {
    Header,
    Footer,
    NavSearch
  },
  mounted() {
    //開けた時またはページをリフレッシュした時、認証しているかをチェック
    //this.$store.dispatch('checkAuth');

    //ログの記録　ログイン中にレフレッシュした時対策
    //const currentUser = this.$store.state.currentUser;
    //console.log('App.vueのmount処理が発火');
    //if (currentUser.role) {
    //  //学生か否かで送るデータを変える
    //  if (currentUser.role != 2) {
    //    window.gtag('event', "custom_user_event", {
    //      'event_category': "page_view",
    //      'event_label': "PV",
    //      'user_role': currentUser.role,
    //      'value' : 1
    //    });
    //  } else {
    //    window.gtag('event', "custom_user_event", {
    //      'event_category': "page_view_student",
    //      'event_label': currentUser.name,
    //      'value' : 1
    //    });
    //  }
    //}
    /*==================================================================
        toggleClass
    ===================================================================*/
    var toggleClass = function toggleClass(node, className) {
      var classNames = className.trim().split(/\s+/),
        nodeClass = (node.getAttribute("class") || "").trim(),
        nodeClassNames = nodeClass.split(/\s+/),
        i = classNames.length;
      while (i--) {
        var index = nodeClassNames.indexOf(classNames[i]);
        if (~index) {
          classNames.splice(i, 1);
          nodeClassNames.splice(index, 1);
        }
      }
      nodeClassNames = nodeClassNames.concat(classNames);
      node.setAttribute("class", nodeClassNames.join(" "));
    };
    /*==================================================================
        getBrows
    ===================================================================*/
    var getBrows = function getBrows() {
      "use strict";
      var _ua = window.navigator.userAgent;
      var _uaSp = window.navigator.userAgent.toLowerCase();
      var version = window.navigator.appVersion.toLowerCase();
      /* browser
      -------------------- */
      if (_ua.indexOf("Edge") > -1) {
        document.querySelector("body").classList.add("edge");
      } else if (_ua.indexOf("Firefox") > -1) {
        document.querySelector("body").classList.add("firefox");
      } else if (_ua.indexOf("Chrome") > -1) {
        document.querySelector("body").classList.add("chrome");
      } else if (_ua.indexOf("Opera") > -1) {
        document.querySelector("body").classList.add("opera");
      } else if (_ua.indexOf("Safari") > -1) {
        document.querySelector("body").classList.add("safari");
      } else {
        document.querySelector("body").classList.add("other");
      }
      /* moblie
      -------------------- */
      if (_uaSp.indexOf("iphone") !== -1) {
        document.querySelector("body").classList.add("iphone");
      } else if (_uaSp.indexOf("ipad") !== -1) {
        document.querySelector("body").classList.add("ipad");
      } else if (_uaSp.indexOf("android") !== -1) {
        if (_uaSp.indexOf("mobile") !== -1) {
          document.querySelector("body").classList.add("android");
        } else {
          document.querySelector("body").classList.add("androidTablet");
        }
      }
      /* ユーザーエージェントでbodyに.pc .spを付与
      -------------------- */
      if (!navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
        document.querySelector("body").classList.add("pc");
        document.querySelector("body").classList.remove("sp");
      } else {
        document.querySelector("body").classList.add("sp");
        document.querySelector("body").classList.remove("pc");
      }
      return false;
    };
    getBrows();
    /*==================================================================
        TEL
        ユーザーエージェント判別で、PC時にリンク(電話のみ)クリックのイベントを無効化
    ===================================================================*/
    var linkClick = function linkClick() {
      "use strict";
      var ua = navigator.userAgent.toLowerCase();
      var isMobile = /iphone/.test(ua) || /android(.+)?mobile/.test(ua);
      var link = document.querySelectorAll('a[href^="tel:"]');
      var linkList = Array.prototype.slice.call(link, 0); //ie
      if (!isMobile) {
        linkList.forEach(function (e) {
          e.addEventListener(
            "click",
            function (i) {
              i.preventDefault();
            },
            false
          );
          e.style.cursor = "default";
        });
      }
    };
    linkClick();
    /*==================================================================
        global MENU
    ===================================================================*/
    (function () {
      let hamburger = document.querySelector(".hamburger");
      let navOpen = document.querySelector(".navOpen");
      let body = document.querySelector("body");
      let menuClick = document.querySelectorAll(".navOpen a");
      let headerHeight = 120;
      if (hamburger != null) {
        var burgerMenu = function burgerMenu() {
          "use strict";
          toggleClass(navOpen, "active");
          toggleClass(hamburger, "active");
          toggleClass(body, "fixed");
          let y = window.scrollY;
          if (y >= headerHeight) {
            hamburger.classList.add("scrolled");
          }
        };
        hamburger.addEventListener("click", function () {
          burgerMenu();
        });
        menuClick.forEach(function (e) {
          e.addEventListener(
            "click",
            function () {
              burgerMenu();
            },
            false
          );
        });
      }
    })();
    function menuScroll(e) {
      let elem = document.querySelector(e);
      var navOpen = document.querySelector(".navOpen");
      if (!elem) return;
      if (window.matchMedia("(min-width:961px)").matches) {
        elem.classList.remove("scrolled");
        window.addEventListener("scroll", function () {
          let y = window.scrollY;
          let headerHeight = 120;
          if (y >= headerHeight) {
            elem.classList.add("scrolled");
          } else if (y < headerHeight) {
            if (navOpen.classList.contains("active") === true) {
              console.log("class");
            } else {
              elem.classList.remove("scrolled");
            }
          }
        });
      } else {
        elem.classList.add("scrolled");
      }
    }
    menuScroll(".hamburger");
    window.addEventListener("resize", function () {
      menuScroll(".hamburger");
    });
    (function () {
      var btn = document.querySelector(".navSearchBtn");
      var navOpen = document.querySelector(".navSearchCommon");
      var body = document.querySelector("body");
      var menuClick = document.querySelectorAll(".navSearch .jsNavSearchSubmitModal");
      if (btn != null) {
        function btnMenu() {
          "use strict";
          let text = document.querySelector(".navSearchBtn span b").textContent;
          if (text === "企業を検索") {
            document.querySelector(".navSearchBtn span b").textContent = "閉じる";
          } else if (text === "閉じる") {
            document.querySelector(".navSearchBtn span b").textContent =
              "企業を検索";
          }
          toggleClass(navOpen, "active");
          toggleClass(btn, "active");
          toggleClass(body, "fixed");
        }
        btn.addEventListener("click", function () {
          btnMenu();
        });
        menuClick.forEach(function (e) {
          e.addEventListener(
            "click",
            function () {
              btnMenu();
            },
            false
          );
        });
      }
    })();
    menuScroll(".navSearchBtn");
    window.addEventListener("resize", function () {
      menuScroll(".navSearchBtn");
      console.log('resizeされた');
    });
    /*==================================================================
        firstload
        ※初回読み込み時のCSSトランジション防止
    ===================================================================*/
    (function () {
      setTimeout(function () {
        let elements = document.querySelectorAll(".firstload");
        let elementslist = Array.prototype.slice.call(elements, 0); //ie
        elementslist.forEach(function (e) {
          e.classList.remove("firstload");
          // console.log("firstload-remove");
        });
      }, 500);
    })();
    /*==================================================================
        smooth-scroll (smooth-scroll.polyfills.min.js)
        .smoothscrollのhref=#で始まるアンカーをクリックした場合に処理
    ===================================================================*/
    var smoothScroll = new SmoothScroll(".smoothScroll", {
      speed: 1000,
      easing: "easeInOutCubic",
      speedAsDuration: true,
    });

  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* 使用する際はtransitionタグにname=fadeとする*/
/* enter-active -> enter-to の順番で書くのが大事*/
.fade-enter-active {
  transition: opacity 1s;
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
/* leave-active -> leave-to の順番で書くのが大事*/
.fade-leave-active {
  transition: opacity 1s;
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
</style>

