<template>

    <body class="login">

    <main id="board" class="wrapper wrapperBd">

        <div class="liPankuzu">
        <ul>
            <li><router-link to="/">ホーム</router-link></li>
            <li>課題解決掲示板</li>
        </ul>
        </div>
        <!-- li-pankuzu -->

        <section class="secMainview01">
        <div class="secMainview01Inner container containerLarge">
            <h1 class="secMainview01Title">
            <img src="/assets/img/common/txtKadaikaiketsu.svg" alt="課題解決掲示板" class="secMainview01Jp" />
            </h1>
        </div>
        </section>
        <!-- secMainview01 -->

        <section class="boardDescription">
            <div class="boardDescriptionInner container containerLarge">
                <div class="effectBlock effectBlock05"></div>
                <div class="boardDescriptionTxt">
                <h2 class="boardDescriptionTitle"><img src="/assets/img/common/txtAboutKadai.svg" alt="課題解決掲示板とは" width="280" height="27" /></h2>
                <p>出展者の強みやお困りごとを掲載し、来場者との商談や情報交換を促進します。各案件をクリックすると詳細情報が表示されます。お問合せは掲載元の企業情報ページよりご連絡ください。</p>
                <ul>
                    <li v-if="authFlag == true && boards_ne_display_flag == true">
                        <a href="#order" class="linkSlash smoothScroll">
                            <span>お困りごと相談</span><br class="spNone">はこちら
                            <div class="linkSlashIcon">
                            <svg width="7" height="7" viewBox="0 0 7 7">
                                <path d="M3.5,0,7,7H0Z" transform="translate(7 7) rotate(180)" fill="#fff"></path>
                            </svg>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#received" class="linkSlash smoothScroll">
                            <span>自社PR</span>はこちら
                            <div class="linkSlashIcon">
                            <svg width="7" height="7" viewBox="0 0 7 7">
                                <path d="M3.5,0,7,7H0Z" transform="translate(7 7) rotate(180)" fill="#fff"></path>
                            </svg>
                            </div>
                        </a>
                    </li>
                </ul>
                </div>
                <div class="boardDescriptionImg">
                <img src="/assets/img/board/boadMainview.png" alt="打ち合わせイメージ" />
                </div>
            </div>
        </section>
        <!-- boardDescription -->

        <div class="boardLogin container" v-if="authFlag == false">
            <div class="btnSlash" @click="linkToLogin"><span class="btnSlashInner">ログイン</span></div><br>
            <a href="https://miceform.jp/otex2023/form/" class="linkIcon"><img src="/assets/img/common/iconAdmin.svg" alt="">会員登録はこちら</a>
        </div>

        <section id="order" class="boardOrder container containerLarge" v-if="authFlag == true && boards_ne_display_flag == true">
            <div class="effectBlock effectBlock04"></div>
            <h2 class="h02">
                <img src="/assets/img/common/txtOkomarigoto.svg" alt="お困りごと相談" />
            </h2>
            <div class="boardListview">
                <ul>
                    <li v-for="boards_ne_value in boards_ne_list" :key="boards_ne_value.id" @click="fetchBoardDetail(boards_ne_value.id)">
                        <a v-bind:class="['linkBoard', boards_ne_value.panel_color]">
                        <span class="linkBoardTitle">{{boards_ne_value.content_title}}</span>
                        <span class="linkBoardTag">{{boards_ne_value.industry_name}}</span>
                        <span class="linkBoardTxt">{{boards_ne_value.company_name}}</span>
                        <span class="linkBoardDate">{{boards_ne_value.created_at}}</span>
                        </a>
                    </li>
                <!--
                <li>
                    <a class="linkBoard cat2">
                    <span class="linkBoardTitle">この文章はサンプルです。ここには文章が入ります。この文章はサンプルです。ここには文章が入ります。</span>
                    <span class="linkBoardTag">タグ名が1点入ります</span>
                    <span class="linkBoardTxt">株式会社 SAMPLE NAME SAMPLE NAMESAMPLE NAMESAMPLE NAMESAMPLE NAMESAMPLE NAME</span>
                    <span class="linkBoardDate">2023.10.25</span>
                    </a>
                </li>
                -->
                </ul>
                <div class="boardListviewBtnarea" @click="isMore(0)" v-if="(boards_ne_list?.length - boards_ne_more_count) >= 0">
                    <label class="btnViewmore" for="viewmore"><button id="viewmore" class="btnViewmoreInner" type="button">もっと見る</button></label>
                </div>
            </div>

        </section>

        <section id="received" class="boardOrder container containerLarge" v-if="authFlag == true">
            <h2 class="h02">
                <img src="/assets/img/common/txtJishaPR.svg" alt="自社PR" />
            </h2>
            <div class="boardListview">
                <ul>
                    <li v-for="boards_pr_value in boards_pr_list" :key="boards_pr_value.id" @click="fetchBoardDetail(boards_pr_value.id)">
                        <a v-bind:class="['linkBoard', boards_pr_value.panel_color]">
                        <span class="linkBoardTitle">{{boards_pr_value.content_title}}</span>
                        <span class="linkBoardTag">{{boards_pr_value.industry_name}}</span>
                        <span class="linkBoardTxt">{{boards_pr_value.company_name}}</span>
                        <span class="linkBoardDate">{{boards_pr_value.created_at}}</span>
                        </a>
                    </li>
                </ul>
                <div class="boardListviewBtnarea" @click="isMore(1)" v-if="(boards_pr_list?.length - boards_pr_more_count) >= 0">
                    <label class="btnViewmore" for="viewmoreReceived"><button id="viewmoreReceived" class="btnViewmoreInner" type="button">もっと見る</button></label>
                </div>
            </div>
        </section>


        <!--
        案件用モーダル
        - class .active付与で表示を切り替え
        - 受注案件の場合は .modalOrder に .Receivedを追加してください。※右上タグ表記が変わります
        - タイトル下のタグ : 親にあるclass .cat番号によって背景カラーが変わるように設定しています。
        - 画像スライダー : splide.jsを使用。比率の異なる画像は設定されている幅・高さに収まるように調整(サンプル画像あり)
        - 説明エリア : 一つのテキストとして出力されることを想定してます。(取り急ぎpre要素として調整)
        - ダウンロードリンク : 最大3件(仮) ほどを想定しています。
            ※管理画面上からはURLとタイトルのみを投稿するのみで、ダウンロード先のページはユーザー側で用意していただく想定
        -->
        <div :class="['modal', 'modalOrder', 'active', [boardDetail.type == 1 ? 'Received' : ''],[boardDetail.panel_color]]" v-if="showModalFlag">
            <div class="modalWrapper">
                <div class="modalInner">
                <div class="modalClose" @click="hideModal"><img src="/assets/img/common/iconPopupClose.svg" alt="閉じる" width="31" height="31" /></div>
                    <!-- contents -->
                    <article class="modalContents">
                    <div class="modalTag"></div>
                    <h2 class="modalContentsTitle">{{boardDetail.content_title}}</h2>
                    <div class="modalContentsTag"><span>{{boardDetail.industry_name}}</span></div>
                    <dl class="modalContentsData">
                    <dt>担当会社</dt>
                    <dd>{{boardDetail.company_name}} </dd>
                    <dt>掲載日</dt>
                    <dd>{{boardDetail.created_at}}</dd>
                    </dl>
                    <div class="modalContentsMainview">

                    <div class="jsSlideModal splide">
                        <div class="splide__track">
                        <ul class="splide__list">
                            <li class="splide__slide" v-if="boardDetail.image_1_url">
                                <img :src="boardDetail.image_1_url" alt="" />
                            </li>
                            <li class="splide__slide" v-if="boardDetail.image_2_url">
                                <img :src="boardDetail.image_2_url" alt="" />
                            </li>
                            <li class="splide__slide" v-if="boardDetail.image_3_url">
                                <img :src="boardDetail.image_3_url" alt="" />
                            </li>
                            <!--
                            <li class="splide__slide">
                            <img src="/assets/img/board/boardModalImg03.jpg" alt="" />
                            </li>
                            <li class="splide__slide">
                            <img src="/assets/img/board/boardModalImg01.jpg" alt="" />
                            </li>
                            -->
                        </ul>
                        </div>
                        <div class="splide__arrows">
                        <button class="splide__arrow splide__arrow--prev">
                            <img src="/assets/img/common/iconArrowSlider.svg" alt="前へ" />
                        </button>
                        <button class="splide__arrow splide__arrow--next">
                            <img src="/assets/img/common/iconArrowSlider.svg" alt="次へ" />
                        </button>
                        </div>
                    </div>
                </div>
                <div class="modalContentsText">
                <pre>{{boardDetail.content_text}}</pre>
                </div>
                <ul class="modalContentsLink">
                    <li><a :href="boardDetail.material_1_url" class="linkUnderline" target="_blank" rel="noopener" v-if="boardDetail.material_1_title" download>{{boardDetail.material_1_title}}</a></li>
                    <li><a :href="boardDetail.material_2_url" class="linkUnderline" target="_blank" rel="noopener" v-if="boardDetail.material_2_title" download>{{boardDetail.material_2_title}}</a></li>
                    <li><a :href="boardDetail.material_3_url" class="linkUnderline" target="_blank" rel="noopener" v-if="boardDetail.material_3_title" download>{{boardDetail.material_3_title}}</a></li>
                </ul>
                <div class="modalContentsBtn" v-if="boardDetail.company_detail_url">
                    <a @click="linkToCompanyDetail(boardDetail.company_detail_url)" class="btnBlock">企業情報を確認する</a>
                </div>
            </article>
            <!-- contents -->
            </div>
            <!-- inner -->
        </div>
        <div class="modalBg"></div>
        </div>
        <!-- modal -->

    </main>
    <!-- #pageID -->
    </body>

</template>

<script setup>
    import { ref, onMounted, inject, computed } from 'vue';
    import Splide from '@splidejs/splide';
    import { onBeforeRouteLeave } from 'vue-router';
    const $axios = inject('$axios');
    import { useStore } from 'vuex';
    import router from '@/router';
    //store/index.jsのグローバルデータ
    const store = useStore();
    const authFlag = computed(() => store.state.authFlag);
    const currentUser = computed(() => store.state.currentUser);

    //変数
    const boards_ne = ref();
    const boards_pr = ref();
    const boardDetail = ref([]);
    const showModalFlag = ref(false);
    const boards_pr_more_count = ref(9);
    const boards_ne_more_count = ref(9);
    const boards_ne_display_flag = ref(false);
    let splide5;

    //board情報を取得
    const get_boards = () => {

        $axios.get('/api/get_boards', {params: {
            user_id: currentUser.value.id
        }})
        .then((res)=> {
            console.log(res);
            boards_ne.value = res.data.boards_ne;
            boards_pr.value = res.data.boards_pr;
            boards_ne_display_flag.value = res.data.boards_ne_display_flag;
        })
        .catch((err)=> {
            console.log(err);
        })
        .finally(()=>{
        })
    }

    const fetchBoardDetail = (id) => {
        $axios.get('/api/get_board_detail?board_id='+id)
        .then((res) => {
            console.log(res);
            boardDetail.value = res.data.board_detail;
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(()=>{
            showModal();
            gtagViewBoard(boardDetail.value.content_title);
            setTimeout(() => {
                splide5 = new Splide(".jsSlideModal", {
                    arrows: true,
                    perMove: 1,
                    pagination: true,
                    flickPower: 300,
                    height: 400,
                    autoHeight: true,
                    gap: 0,
                    breakpoints: {
                        960: {
                            height: 300,
                        },
                    },
                });
                splide5.mount();
            }, 300);
        });
    }

    const showModal = () => {
        showModalFlag.value = true;
    }

    const hideModal = () => {
        showModalFlag.value = false;
        splide5.destroy();
    }

    const boards_ne_list = computed(() => {
        if (boards_ne.value) {
            return boards_ne.value.slice(0, boards_ne_more_count.value);
        }
    });

    const boards_pr_list = computed(() => {
        if (boards_pr.value) {
            return boards_pr.value.slice(0, boards_pr_more_count.value);
        }
    });

    const isMore = (type) => {
        if (type == 1) {
            boards_pr_more_count.value += boards_pr.value.length;
        } else {
            boards_ne_more_count.value += boards_ne.value.length;
        }
    }

    //gタグへログ保存
    const gtagViewBoard = (content_title) => {
        const label = content_title;
        gtag('event', "custom_user_event", {
            'event_category': "view_board",
            'event_label': label,
            'value' : 1
        });
    }

    const linkToCompanyDetail = (url) => {
        console.log(url);
        router.push(url);
    }

    //未ログインで課題解決掲示板を選択→ログイン→課題解決掲示板になるため
    const linkToLogin = () =>{
        sessionStorage.setItem('failedPath', '/board');
        router.push('/login');
    }


    // mounted
    onMounted(() => {
        get_boards();

        //重複初期化を避けるために要素をページ離脱前に削除
        onBeforeRouteLeave((to, from, next) => {
            if (splide5) {
                setTimeout(() => {
                    splide5.destroy();
                }, 1000);
            }
            next();
        });
    });
</script>



//<script>
//import Splide from '@splidejs/splide';
//
//export default {
//  components: {
//    Splide, // VueコンポーネントとしてSplideを登録
//  },
//  mounted() {
//    const splide5 = new Splide(".jsSlideModal", {
//        arrows: true,
//        perMove: 1,
//        pagination: true,
//        flickPower: 300,
//        height: 400,
//        autoHeight: true,
//        gap: 0,
//        breakpoints: {
//            960: {
//                height: 300,
//            },
//        },
//    });
//    splide5.mount();
//  },
//};
//</script>