<template>
    <body class="login">

    <!--<vue-element-loading :active="dataLoading" color="black" spinner="spinner" />-->

    <main id="companyDetail" class="wrapper">
        <section class="companyMainview">
            <div class="liPankuzu White">
                <ul>
                    <li><router-link to="/">ホーム</router-link></li>
                    <li @click="router.push('/company')"><a>出展企業情報</a></li>
                    <li>{{company.company_name}}</li>
                </ul>
            </div>
            <div class="previous-next-buttons">
                <template v-if="company.previous_related_company">
                    <a
                        @click.prevent="toCompanyDetail(company.previous_related_company.id)"
                        :title=company.previous_related_company.company_name
                    >
                        <img
                            @mouseover="previousHover = true"
                            @mouseleave="previousHover = false"
                            :src="previousHover
                                ? '/assets/img/company/previousBtnHover.svg'
                                : '/assets/img/company/previousBtn.svg'"
                            alt="前へボタン"
                        >
                    </a>
                </template>
                <template v-if="company.next_related_company">
                    <a
                        @click.prevent="toCompanyDetail(company.next_related_company.id)"
                        :title=company.next_related_company.company_name
                    >
                        <img
                            @mouseover="nextHover = true"
                            @mouseleave="nextHover = false"
                            :src="nextHover
                                ? '/assets/img/company/nextBtnHover.svg'
                                : '/assets/img/company/nextBtn.svg'"
                            alt="次へボタン"
                        >
                    </a>
                </template>
            </div>
            <div class="companyMainviewInner container">
                <div class="companyMainviewInfo">
                <span class="companyMainviewTag">{{company.industry_name}}</span>
                <h1 class="companyMainviewTitle">{{company.company_name}}</h1>
                <ul class="companyMainviewList">
                    <li v-if="company.is_liked"><button @click="deleteFavoriteList(company.id)" type="button" class="companyMainviewBtn comapanyMainviewFavorite cat1"><img src="/assets/img/common/iconFavorite.svg" alt="" />お気に入り済み</button></li>
                    <li v-if="!company.is_liked"><button @click="addFavoriteList(company.id)" type="button" class="companyMainviewBtn comapanyMainviewFavorite"><img src="/assets/img/common/iconFavorite.svg" alt="" />お気に入りに追加</button></li>
                    <li v-if="company.is_displayed_in_boards"><span class="companyMainviewBoard companyMainviewBtn" @click="linkToBoard()">課題解決掲示板 掲載中</span></li>
                    <li>
                    <button v-if="company.is_displayed_in_rally" type="button" class="companyMainviewBtn companyMainviewRalley jsCompanyRalleyBtn">企業発見ラリー参加中！</button>
                    <div class="popup" id="jsPopup1">
                        <div class="popupWrapper">
                        <div class="popupInner">
                            <div class="popupClose"><img src="/assets/img/common/iconPopupClose.svg" alt="閉じる" width="31" height="31"></div>
                            <div class="topRealExhibition popupItem">
                            <div class="topRealExhibitionTxt">
                                <p class="topRealExhibitionTitle"><img src="/assets/img/common/txtAboutRalley.svg" alt="企業発見ラリーとは"></p>
                                <p>企業発見ラリーとは、OTEXリアル展の来場学生が岡山県企業を知ることができる企画です。学生の採用や会社PRに意欲がある出展企業が参加し、学生との交流の機会を設けています。 </p>
                                <dl>
                                <dt>開催日時</dt>
                                <dd>2023年11月07日 ～ 2023年11月08日</dd>
                                <dt>会場</dt>
                                <dd>コンベックス岡山 大・中・小展示場</dd>
                                </dl>
                            </div>
                            <div class="topRealExhibitionSlide">
                                <img src="/assets/img/top/otexSlideShowImg06.png" alt="">
                            </div>
                            <!-- slide -->
                            </div>
                            <!-- item -->
                        </div>
                        <!-- inner -->
                        </div>
                        <div class="popupBg"></div>
                    </div>
                    <!-- popup slideshow -->
                    </li>
                </ul>
                </div>
                <div class="companyMainviewIcon">
                    <img v-if="company.logo_image_path" :src="company.logo_image_path" alt="" @error="altImg">
                    <img v-else src="/assets/img/company/companylogo.jpg" alt="{企業名}" />
                </div>
                <div class="companyMainviewBg">
                    <img v-if="company.background_image_path" :src="company.background_image_path" alt="" @error="altBackImg">
                    <img v-else src="/assets/img/company/companyKeyvisual.jpg" alt="{企業名}" />
                </div>
            </div>
        </section>
        <!-- companyMainview -->

        <div class="companyPr container">
        <div class="companyPrIntro">
            <div class="companyPrDesc">
            <p>{{company.catch_copy}}</p>
            </div>
            <div class="companyPrInfo">
            <dl>
                <dt>連絡先</dt>
                <dd>TEL {{company.telephone_number}}<br>{{company.fax_number ? "FAX "+company.fax_number : ""}}<br />
                {{company.email}}
                </dd>
                <dt>URL</dt>
                <dd><a :href="`${company.official_url}`" target="_blank" class="linkUnderline" @click="gtagDbAccessHomePage">{{company.official_url}}</a></dd>
            </dl>
            </div>
        </div>
        <div class="companyPrList">
            <p class="companyPrListTitle"><span><img src="/assets/img/common/txtPrpoint.svg" alt="PRポイント" /></span></p>
            <ul v-if="company.advantages">
                <li v-for="(advantage, index) in company.advantages" :key="index"><span>{{advantage}}</span></li>
            </ul>
        </div>
        <div class="effectBlock effectBlock01"></div>
        </div>
        <!-- companyPr -->

        <div class="companyMovie">
        <div class="companyMovieInner container">
            <div class="effectBlock effectBlock02"></div>

            <!-- 動画がある場合 -->
            <button type="button" class="companyMovieBtn" @click="showVideoModal" v-if="company.video_url">
                <span><img src="/assets/img/common/iconPlayColor.svg" alt="" /></span>
                <img v-if="company.background_image_path" :src="company.background_image_path" alt="" @error="altBackImg" class="companyMovieBtnImg">
                <img v-else src="/assets/img/company/companyKeyvisual.jpg" alt="{企業名}" class="companyMovieBtnImg"/>
            </button>
            <div class="modal active" v-if="showVideoModalFlag">
                <div class="modalWrapper">
                    <div class="modalInner">
                    <div class="modalClose" @click="hideVideoModal"><img src="/assets/img/common/iconPopupClose.svg" alt="閉じる" width="31" height="31" /></div>
                        <vue-element-loading :active="videoLoadingFlag" color="black" spinner="spinner" />
                        <iframe @load="onIframeLoad" width="560" height="315" :src="youTubeUrl(company.video_url)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="modalBg"></div>
            </div>
            <!-- modal active付与で表示切り替え -->
            <!-- 動画がある場合 -->
            <!-- 動画ではなくメインイメージが掲載-->
            <div class="companyMovieImg" v-if="!company.video_url">
                <img v-if="company.background_image_path" :src="company.background_image_path" alt="" @error="altBackImg">
                <img v-else src="/assets/img/company/companyKeyvisual.jpg" alt="{企業名}" />
            </div>
            <!-- 動画ではなくメインイメージが掲載-->
        </div>
        </div>
        <!-- companyMovie -->

        <section class="companyDocument">
        <div class="companyDocumentInner container">
            <div class="effectBlock effectBlock04"></div>
            <h2 class="h01 h01Document">
            <span class="h01Inner">
                <span class="h01Jp">
                <img src="/assets/img/common/txtSeihinjoho.svg" alt="製品情報" />
                </span>
                <span class="h01En"><img src="/assets/img/common/txtDocument.svg" alt="DOCUMENT" width="507" height="71" /></span>
            </span>
            </h2>
            <div class="companyDocumentList">
            <ul>
                <li v-if="company.pdf_path_1">
                    <a :href="company.pdf_path_1" class="linkCompanydocument" target="_blank" @click="gtagDbDownloadDocument(4)" download>
                        <img v-if="company.pdf_thumbnail_path_1" :src="company.pdf_thumbnail_path_1" alt="{資料タイトル}" @error="altBackImg" />
                        <img v-else src="/assets/img/company/companyKeyvisual.jpg" alt="{企業名}" class="companyMovieBtnImg"/>
                        <span>{{company.panel_name_1}}</span>
                    </a>
                </li>
                <li v-if="company.pdf_path_2">
                    <a :href="company.pdf_path_2" class="linkCompanydocument" target="_blank" @click="gtagDbDownloadDocument(5)" download>
                        <img v-if="company.pdf_thumbnail_path_2" :src="company.pdf_thumbnail_path_2" alt="{資料タイトル}" @error="altBackImg" />
                        <img v-else src="/assets/img/company/companyKeyvisual.jpg" alt="{企業名}" class="companyMovieBtnImg"/>
                        <span>{{company.panel_name_2}}</span>
                    </a>
                </li>
                <li v-if="company.pdf_path_3">
                    <a :href="company.pdf_path_3" class="linkCompanydocument" target="_blank" @click="gtagDbDownloadDocument(6)" download>
                        <img v-if="company.pdf_thumbnail_path_3" :src="company.pdf_thumbnail_path_3" alt="{資料タイトル}" @error="altBackImg" />
                        <img v-else src="/assets/img/company/companyKeyvisual.jpg" alt="{企業名}" class="companyMovieBtnImg"/>
                        <span>{{company.panel_name_3}}</span>
                    </a>
                </li>
                <li v-if="company.pdf_path_4">
                    <a :href="company.pdf_path_4" class="linkCompanydocument" target="_blank" @click="gtagDbDownloadDocument(7)" download>
                        <img v-if="company.pdf_thumbnail_path_4" :src="company.pdf_thumbnail_path_4" alt="{資料タイトル}" @error="altBackImg" />
                        <img v-else src="/assets/img/company/companyKeyvisual.jpg" alt="{企業名}" class="companyMovieBtnImg"/>
                        <span>{{company.panel_name_4}}</span>
                    </a>
                </li>
                <li v-if="company.pdf_path_5">
                    <a :href="company.pdf_path_5" class="linkCompanydocument" target="_blank" @click="gtagDbDownloadDocument(8)" download>
                        <img v-if="company.pdf_thumbnail_path_5" :src="company.pdf_thumbnail_path_5" alt="{資料タイトル}" @error="altBackImg" />
                        <img v-else src="/assets/img/company/companyKeyvisual.jpg" alt="{企業名}" class="companyMovieBtnImg"/>
                        <span>{{company.panel_name_5}}</span>
                    </a>
                </li>
            </ul>
            </div>
        </div>
        </section>
        <!-- companyDocument -->

        <section class="companyOutline container containerLarge">
        <div class="companyOutlineInner container">
            <div class="effectBlock effectBlock05"></div>
            <h2 class="h01 h01Outline">
            <span class="h01Inner">
                <span class="h01Jp">
                <img src="/assets/img/common/txtKigyogaiyo.svg" alt="企業概要" />
                </span>
                <span class="h01En"><img src="/assets/img/common/txtOutline.svg" alt="OUTLINE" /></span>
            </span>
            </h2>
            <table class="table01">
            <tbody>
                <tr>
                <th>会社名</th>
                <td>{{company.company_name}}</td>
                </tr>
                <tr>
                <th>代表者</th>
                <td>{{company.representative_position}} {{company.representative_name}}</td>
                </tr>
                <tr>
                <th>創業</th>
                <td>{{company.founded_year}}年{{company.founded_month}}月{{company.founded_day}}日</td>
                </tr>
                <tr>
                <th>資本金</th>
                <td>{{company.capital}}千円</td>
                </tr>
                <tr>
                <th>従業員</th>
                <td><span v-if="company.employees_number_total">{{company.employees_number_total}}人</span> <span v-if="company.employees_number_office">事業所：{{company.employees_number_office}}人</span></td>
                </tr>
                <tr>
                <th>住所</th>
                <td>〒{{company.postal_code}} {{company.address}}</td>
                </tr>
                <tr>
                <th>連絡先</th>
                <td>TEL : {{company.telephone_number}} / {{company.fax_number ? "FAX : "+company.fax_number : ""}} <br>E-mail {{company.email}}</td>
                </tr>
                <tr>
                <th>URL</th>
                <td><a :href="company.official_url" target="_blank">{{company.official_url}}</a></td>
                </tr>
                <tr>
                <th>事業内容</th>
                <td>{{company.business_content}}</td>
                </tr>
            </tbody>
            </table>
        </div>
        </section>
        <!-- companyOutline -->

        <section class="companyRelated container containerLarge">
            <div class="companyRelatedInner container">
                <h2 class="h01 h01Outline">
                    <span class="h01Jp">
                    <img src="/assets/img/common/txtSameCompany.svg" alt="同じカテゴリーの企業" />
                    </span>
                    <span class="h01En"><img src="/assets/img/common/txtCompany.svg" alt="COMPANY" /></span>
                </h2>
                <div class="relatedCompanyList">
                    <ul>
                        <li v-for="company in relatedCompanies" :key="company.id">
                            <router-link :to="`/companyDetail?company_id=${company.id}`" v-bind:class="['linkCompanycard', company.panel_color]">
                                <div class="linkCompanycardImg">
                                    <img v-if="company.background_image_path" :src="company.background_image_path" alt="" @error="altImg">
                                    <img v-else src="/assets/img/top/pickupThumb01.jpg" alt="{企業名}" />
                                </div>
                                <span class="linkCompanycardTitle"><em>{{company.company_name}}</em></span>
                                <span class="linkCompanycardExc">{{ truncateText(company.catch_copy) }}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- companyRelated -->

        <section class="companyContact container containerLarge" v-if="currentUser.role_id != 4">
            <div class="effectBlock effectBlock04"></div>
            <div class="companyContactInner container">
                <h2 class="companyContactTitle">
                <span class="Jp"><em>{{company.company_name}}</em>へのお問い合わせ</span>
                <span class="En"><img src="/assets/img/common/txtContact.svg" alt="CONTACT" width="410" height="71"  /></span>
                </h2>
                <ul class="companyContactList">
                <li :style="[company.business_card_url ? '': 'width: 100%;']">
                    <router-link :to="`/contact?company_id=${company.id}`" class="btn" @click="gtagInquiry(company.company_name)">
                        <span><img src="/assets/img/common/iconContact.svg" alt="" width="29" height="22" />WEB商談お申し込み・お問い合わせ</span>
                    </router-link>
                </li>
                <li v-if="company.business_card_url">
                    <a :href="company.business_card_url" target="_blank" class="btn" @click="gtagBusinessCard(company.company_name)">
                        <span><img src="/assets/img/common/iconPhone.svg" alt="" width="17" height="27" />アプリで名刺交換</span>
                    </a>
                </li>
                </ul>
            </div>
            <div class="effectBlock effectBlock01"></div>
        </section>
        <!-- companyContact -->

        <section class="secPickup">

        <div class="secPickupIntro container containerLarge">
            <h2 class="h01 h01Pickup">
            <span class="h01Inner">
                <span class="h01Jp"><img src="/assets/img/common/txtSonohokaNoKigyoWhite.svg" alt="そのほかの企業" width="234" height="38" /></span>
                <span class="h01En"><img src="/assets/img/common/txtPickupEnWhite.svg" alt="PICKUP" /></span>
            </span>
            </h2>
            <div class="effectBlock effectBlock02"></div>
        </div>
        <div class="secPickupBtnarea container containerLarge">
            <router-link to="/company" class="linkSlash linkSlashWhite">
                企業情報一覧はこちら
                <div class="linkSlashIcon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7">
                    <path d="M3.5,0,7,7H0Z" transform="translate(7) rotate(90)" fill="#10151a" />
                    </svg>
                </div>
            </router-link>
        </div>
        <div class="secPickupSlide jsSlide01 splide">
            <div class="splide__track">
            <ul class="splide__list">
                <li class="splide__slide" v-for="company in pickupCompanies" :key="company.id">
                    <router-link :to="`/companyDetail?company_id=${company.id}`" class="secPickupSlideBox">
                    <div class="secPickupSlideBoxImg">
                        <img v-if="company.background_image_path" :src="company.background_image_path" alt="" @error="altBackImg">
                        <img v-else src="/assets/img/company/companyKeyvisual.jpg" alt="{企業名}" class="companyMovieBtnImg"/>
                    </div>
                    <span class="secPickupSlideBoxTag">{{company.industry_name}}</span>
                    <span class="secPickupSlideBoxTitle">{{company.company_name}}</span>
                    </router-link>
                </li>
            </ul>
            </div>
            <div class="splide__arrows">
            <button class="splide__arrow splide__arrow--prev">
                <img src="/assets/img/common/iconArrowSliderWhite.svg" alt="前へ" />
            </button>
            <button class="splide__arrow splide__arrow--next">
                <img src="/assets/img/common/iconArrowSliderWhite.svg" alt="次へ" />
            </button>
            </div>
        </div>
        <!-- slide -->
        </section>
        <!-- secPickup -->

    </main>
    <!-- #pageID -->
    </body>
</template>

<script setup>
    import { ref, onMounted, inject, computed, reactive, watch } from 'vue';
    import Splide from '@splidejs/splide';
    import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
    const $axios = inject('$axios');
    import VueElementLoading from "vue-element-loading";

    const route = useRoute();
    const company = ref([]);
    const currentUser = computed(() => store.state.currentUser);

    import { useStore } from 'vuex';
    //store/index.jsのグローバルデータ
    const store = useStore();
    const router = useRouter();

    const showVideoModalFlag = ref(false);
    const videoLoadingFlag = ref(false);
    const pickupCompanies = ref([]);
    const relatedCompanies = ref([]);
    const previousHover = ref(false);
    const nextHover = ref(false);
    const dataLoading = ref(true);

    let splide6;

    // pickupの企業類を押したときにページが変わらないためqueryのcompany_idが変わったことで
    // 会社詳細とpickup企業を再取得する
    const companyId = computed(() => route.query.company_id);
    watch(companyId,(newValue, oldValue) => {
        fetchCompanyDetail();
        fetchPickUpCompany();
        fetchRelatedCompanies();
    });

    onMounted(async () => {
        previousHover.value = false;
        nextHover.value = false;
        await fetchCompanyDetail();
        fetchPickUpCompany();
        fetchRelatedCompanies();
        setTimeout(() => {
            topSlideshowPopup("jsPopup1", ".jsCompanyRalleyBtn");
        }, 1000);

        //重複初期化を避けるために要素をページ離脱前に削除
        onBeforeRouteLeave((to, from, next) => {
            setTimeout(() => {
                splide6.destroy();
            }, 1000);
            next();
        });
    });

    //会社情報を取得
    const fetchCompanyDetail = async () => {
        await $axios.get('/api/get_company_detail', {
            params: {
                company_id: route.query.company_id,
                user_id: currentUser.value.id
            }
        }).then((res) => {
            company.value = res.data.company;
            //ログの保存
            saveUserLog(null);
        }).catch((err) => {
            // console.log(err);
        }).finally(()=>{
            gtagAccessBooth();
            dataLoading.value = false;
        });
    }

    //ピックアップ企業の取得
    const fetchPickUpCompany = () => {
        $axios.get('/api/get_pickup_companies')
        .then(res => {
            console.log(res.data.companies);
            pickupCompanies.value = res.data.companies;
        })
        .catch(err => {
            console.error(err);
        })
        .finally(()=> {
            splide6 = new Splide(".jsSlide01", {
                updateOnMove: true,
                type: "loop",
                arrows: true,
                pagination: false,
                perPage: 3,
                perMove: 1,
                focus: "center",
                trimSpace: false,
                flickPower: 100,
                interval: 5000,
                speed: 400,
                autoplay: true,
                breakpoints: {
                    960: {
                    perPage: 1,
                    },
                },
            });
            splide6.mount();
        });
    };

    // 同カテゴリの企業取得
    const fetchRelatedCompanies = () => {
        console.log('fetch related', company.value)
        $axios.get('/api/related-three-companies', {
            params: {
                current_company_id: companyId.value,
                target_industry_check: company.value.industry_check
            }
        }).then((res) => {
            relatedCompanies.value = res.data
            console.log('related', res.data)
        })
    }

    const toCompanyDetail = (company_id) => {
        previousHover.value = false
        nextHover.value = false
        router.push(`/companyDetail?company_id=${company_id}`)
    }

    //popup リアル展スライドショー
    function topSlideshowPopup(e, btn) {
        var popup = document.getElementById(e);
        if (!popup) return;
        var blackBg = document.querySelector(".popupBg");
        var closeBtn = document.querySelector(".popupClose");
        var showBtn = document.querySelectorAll(btn);
        showBtn.forEach(function (e) {
            e.addEventListener(
            "click",
            () => {
                popup.classList.toggle("popupShow");
                if (popup.classList.contains("popupShow") == true) {
                document.body.style.overflowY = "hidden";
                } else {
                document.body.style.overflowY = "auto";
                }
            },
            false
            );
        });
        function togglePopUp(elem) {
            if (!elem) return;
            elem.addEventListener("click", function () {
            popup.classList.toggle("popupShow");
                if (popup.classList.contains("popupShow") == true) {
                    document.body.style.overflowY = "hidden";
                } else {
                    document.body.style.overflowY = "auto";
                }
            });
        }
        togglePopUp(blackBg);
        togglePopUp(closeBtn);
    }

    const altImg = (event) => {
        event.target.src = "/assets/img/company/companylogo.jpg"; 
    }

    const altBackImg = (event) => {
        event.target.src = "/assets/img/company/companyKeyvisual.jpg"; 
    }

    const showVideoModal = () => {
        showVideoModalFlag.value = true;
        videoLoadingFlag.value = true;
        gtagDbViewVideo();
    }

    const hideVideoModal = () => {
        showVideoModalFlag.value = false;
    }

    const onIframeLoad = () => {
        videoLoadingFlag.value = false;
    }

    const youTubeUrl = (video_url) => {
        const array = video_url.split('/');
        let youTubeId = array[array.length - 1];
        youTubeId = youTubeId.replace('watch?v=', ''); //watch?v=を削除
        youTubeId = youTubeId.split('&')[0]; //末尾の&以降を削除
        console.log(youTubeId);
        return `https://www.youtube.com/embed/${youTubeId}`;
    }

    const addFavoriteList = (company_id) => {
        $axios.post('/api/add_favorite_list', {
            company_id: company_id, 
            user_id: currentUser.value.id
        }).then((res) => {
            fetchCompanyDetail()
        });
    }

    const deleteFavoriteList = (company_id) => {
        $axios.post('/api/delete_favorite_list', {
            company_id: company_id, 
            user_id: currentUser.value.id
        }).then((res) => {
            fetchCompanyDetail()
        });
    }

    //課題掲示板掲載中というものを押した時に課題掲示板ページへ遷移
    const linkToBoard = () => {
        router.push('/board');
    }
    
    //問い合わせ数
    const gtagInquiry = (company_name) => {
        const label = 'in_' + company_name;
        gtag('event', "custom_user_event", {
            'event_category': "form_inquiry",
            'event_label': label,
            'value' : 1
        });
    }

    //web商談数
    const gtagNegotiation = (company_name) => {
        const label = 'ne_' + company_name;
        gtag('event', "custom_user_event", {
            'event_category': "form_negotiation",
            'event_label': label,
            'value' : 1
        });
    }

    //名刺交換
    const gtagBusinessCard = (company_name) => {
        const label = 'bc_' + company_name;
        gtag('event', "custom_user_event", {
            'event_category': "access_business_card",
            'event_label': label,
            'value' : 1
        });
    }

    //企業詳細ページアクセスした時にカウント
    const gtagAccessBooth = () => {
        console.log('ac_'+ company.value.company_name);
        const label = 'ac_'+ company.value.company_name
        const industryName = company.value.industry_name
        const currentUser = store.state.currentUser
        gtag('event', "custom_user_event", {
            'event_category': "access_booth",
            'event_label': label,
            'user_role': currentUser.role,
            'industry_name': industryName,
            'value' : 1
        });
    }

    //動画閲覧数
    const gtagDbViewVideo = () => {
        //gtagのついでにデータベースログも保存
        saveUserLog(0);
        console.log('動画見た');
        //gtagのログ保存
        const label = 'mv_'+ company.value.company_name
        gtag('event', "custom_user_event", {
            'event_category': "view_video",
            'event_label': label,
            'value' : 1
        });
    }

    //資料閲覧数
    const gtagDbDownloadDocument = (documentNum) => {
        //currentUser情報がないときに処理ストップ
        if (!currentUser.value.id) {
            console.log('user情報なしでログ保存できない');
            return;
        }

        //データベースログ保存
        saveUserLog(documentNum);
        //gtagのログ保存
        const label = 'dl_'+ company.value.company_name;
        const number = documentNum;
        gtag('event', "custom_user_event", {
            'event_category': "download_document",
            'event_label': label,
            'document_number': number,
            'value' : 1
        });
    }

    //ホームページアクセス数
    const gtagDbAccessHomePage = () => {
        //gtagのついでにデータベースログも保存
        saveUserLog(1);
        //gtagのログ保存
        const label = 'hp_'+ company.value.company_name
        gtag('event', "custom_user_event", {
            'event_category': "access_homepage",
            'event_label': label,
            'value' : 1
        });
    }

    //データベースに保存するログの処理
    const saveUserLog = async(user_log_index) => {
        const formData = reactive({
            user_id: currentUser.value.id,
            company_id: company.value.id,
            user_log_index: user_log_index,
        });
        //console.log(user_log_index);
        await $axios.post('/api/user/log', formData)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const truncateText = (text) => {
        if (text?.length > 100) {
            return text.substring(0, 100) + '...';
        }
        return text;
    };

</script>
