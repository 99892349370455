import { createStore } from "vuex";

const store = (axiosInstance) => createStore({
  state: {
    authFlag: false,
    searchParams: [],
    currentUser: '',
    forceCompanySearch: false,
    isMoreCount: 9,
    topPageLectureInfo: '',
    topPagePresentationFlagNum: '',
    currentMyPageShowTabType: 'recommendations',
    unAuthLinkFromTopPageFlag: false,
    topPageLogSubmittedFlag: false,
  },
  mutations: {
    setAuthFlag(state, flag) {
      state.authFlag = flag;
    },
    setIndustryTopSearch(state,type) {
      state.searchParams[type] = true;
    },
    setCurrentUser(state, data) {
      state.currentUser = data;
    },
    setForceCompanySearch(state) {
      state.forceCompanySearch = true;
      //企業ページ上に飛んだ後にfalseに戻しておく
      setTimeout(() => {
        state.forceCompanySearch = false;
      }, 1000);
    },
    setTopPageLectureInfo(state, data) {
      state.topPageLectureInfo = data;
    },
    setTopPagePresentationFlagNum(state, data) {
      state.topPagePresentationFlagNum = data;
    },
    plusIsMoreCount(state, value) {
      state.isMoreCount += value;
    },
    setCurrentMyPageShowTabType(state, data) {
      state.currentMyPageShowTabType = data;
    },
    setUnAuthLinkFromTopPageFlag(state) {
      state.unAuthLinkFromTopPageFlag = true;
    },
    setTopPageLogSubmittedFlag(state) {
      //これを設定することで同ユーザーがリロード以外でtopページを2度訪れた時に
      //トップページ訪問ログがカウントされないようになる
      state.topPageLogSubmittedFlag = true;
    },
    resetSearchParams(state) {
      console.log('reset');
      state.searchParams = [];
    },
    resetCurrentUser(state) {
      //認証フラグが取れるapiで401を出すと時、クッキーの有効期限が切れた時用。
      //window.location.hrefでreloadをすることで認証状態の値をリセットできるようになる
      if (sessionStorage.getItem('unAuthReloadFlag') == 'true') {
        sessionStorage.removeItem('unAuthReloadFlag');
        window.location.href = '/';
      }
      state.currentUser = [];
      console.log('reset');
    },
    resetIsMoreCount(state) {
      state.isMoreCount = 9;
    },
    resetTopPageLectureInfo(state) {
      state.topPageLectureInfo = '';
    },
    resetTopPagePresentationFlagNum(state) {
      state.topPagePresentationFlagNum = '';
    },
    resetUnAuthLinkFromTopPageFlag(state) {
      state.unAuthLinkFromTopPageFlag = false;
    },
  },
  actions: {
    async checkAuth(context) {
      await axiosInstance.get('/api/check-login')
        .then(response => {
            console.log('ログインしている');
            context.commit('setAuthFlag', true);
            context.commit('setCurrentUser', response.data);
        })
        .catch(error => {
            console.log('ログインしていない');
            context.commit('setAuthFlag', false);
            context.commit('resetCurrentUser');
        });
    },
  },
  getters: {
    getAuthFlag(state) {
      return state.authFlag;
    }
  }
});

export default store;